body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#main-wrapper {
  padding: 0 0 100px;
  position: relative;
}

.footer {
  bottom: 0;
  /* height: 100px; */
  left: 0;
  position: absolute;
  width: 100%;
  text-align: center;
}

.dropdown-item {
  cursor: pointer;
}

tfoot {
  display: table-header-group;
}

.toggle-vis {
  cursor: pointer;
}

table {
  font-size: 10px;
  width: 100%;
  table-layout: fixed;
}

.input-class-footer {
  width: -webkit-fill-available;
}

button {
  font-size: 12px !important;
}

td {
  white-space: pre-wrap;
  word-wrap: break-word
}

.message-container {
  height: 250px;
  overflow: auto;
}

.message {
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  max-width: 70%;
  clear: both;
}

.left-message {
  background-color: #e6e6e6;
  /* Set left message background color */
  float: left;
}

.right-message {
  background-color: #19C37D;
  /* Set right message background color */
  float: right;
}


@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
  .row col-sm-12 col-lg-12 {
    display: flex;
    height: 339px;
    margin-bottom: 2%;
    flex-wrap: wrap;
  }

}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {

  .division1,
  #importantCandidatesTable {
    display: block;
    margin-bottom: 5%;

  }

  .col-lg-7,
  .col-lg-5 {
    width: 100%;
  }

  .btn.col-4.m-0 {
    width: 100%;
  }
}

@media(max-width:760) {
  .s-b1 {
    margin-top: -40px;
  }
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
  .division {
    display: flexbox;
    height: 20px;
    margin-left: auto;
  }

  .r-b {
    margin-left: 5%
  }

  .r-br {
    margin-right: 5%
  }

  .s-b {
    margin-top: -60px;
  }

}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
  .rdivision {
    padding: 10px;
    margin-top: 25%;
  }

  .div4 {
    margin-top: 40%;
  }

  .hdiv {
    overflow: auto;
    margin-top: 100%;
  }

  .rdivision h5 {
    font-size: px;
    max-width: fit-content;
  }

  #placementsTable {
    font-size: px;
  }

  #placementsTable th,
  #placementsTable td {
    padding: 5px;
  }
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {

  .rwd-table table,
  .rwd-table thead,
  .rwd-table tbody,
  .rwd-table tfoot,
  .rwd-table th,
  .rwd-table td,
  .rwd-table tr {
    display: block;
  }

  .rwd-table thead,
  .rwd-table tfoot tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .rwd-table tr {
    border: 1px solid #ccc;
  }

  .rwd-table td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    text-align: left;

    &:before {
      content: attr(data-th)": ";
      font-weight: bold;
      width: 6.5em;
      display: inline-block;
    }

  }
}


@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {

  .dataTable span,
  .dataTable label,
  .dataTable div {
    display: block;

  }

  .dataTable div[role="columnheader"] {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .dataTable label {
    border: 1px solid #ccc;
  }

  .dataTable span {
    border: none;
    padding: 10px;
    border-bottom: 1px solid #eee;
    position: relative;
    text-align: left;

    &:before {
      content: attr(data-label)": ";
      font-weight: bold;
      font-size: 75%;
      /* width: 7.5em; */
      display: inline-block;
    }
  }
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
  .cb {
    margin-top: -25%;
  }
}

@media only screen and (max-width: 760px),
(max-device-width: 1024px) {
  .move-left {
    margin-left: 63% !important;
    float: left !important;
  }

  .move-left1 {
    margin-left: 123% !important;
    float: left !important;
  }

  .move-left2 {
    margin-left: 173% !important;
    float: left !important;
  }

}

@media (max-width:280px) {
  .move-left {
    margin-left: 100% !important;
    margin-top: -35% !important;
  }

  .move-left1 {
    margin-left: 20% !important;
    margin-top: 15% !important;

  }

  .move-left2 {
    margin-left: 100% !important;
    margin-top: -30% !important;
  }

}