.border {
  border-style: groove;
}

.skill {
  /* width: 150px; */
  height: 200px;
  overflow: auto;

}

.vertical-center {
  position: relative;
  left: 50%;
}

.my-table {
  table-layout: fixed;
  width: 100%;
  word-break: break-all;
}

.my-nav {
  color: white !important
}

.my-nav-main {
  background-color: #000;
  z-index: 9999;
}

.my-nav-user {
  background-color: #000;
  cursor: none
}

.log-event {
  padding: 10px;
  border-style: solid !important;
  border-color: rgba(0, 0, 0, 0.247) !important;
  border-radius: 5px;
  width: 100%;
}

.event-notes {
  clear: both;

  display: inline-block;
  /* overflow: auto; */
  white-space: nowrap;
}

mark {
  padding: 0;
  background: yellow !important;
}

/* html {
  overflow:   scroll;
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent; 
} */
html {
  overscroll-behavior-x: none;
}

body {
  overscroll-behavior-x: none;
  font-size: 13px !important
}

/* td {
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis;
} */

/* ::-webkit-scrollbar {
  width: 0;  
  background: transparent;  
}
::-webkit-scrollbar-thumb {
  background: #898989;
} */
@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
  .ndiv{
    
    width: auto;
    margin-bottom: 15%;
  
  }
  
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
  .opt{

    display: flex;
    flex-direction: column;
  } 
}
@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
  .sopt{
    margin-left: 50px;
  }
  .s-opt{
    margin-left: 60px;
  }
  .s-lpt{
    margin-left: 10px;
  }
}
@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
  .ncof{
    flex-direction: column;
  }
  .nlf {
    width: 200%;
  }
  .nlfr {
    width: 160%;
  }
  .nlfw {
    width: 350%;
  }
  .nlfm {
    width: 230%;
  }
  .nlfj {
    width: 300%;
  }
  .ncofs{
    margin-bottom: 10%;
  }
  .nlfws {
    width: 650%;
  }
  .nlfwj {
    width: 550%;
  }
  .nlfs {
    width: 410%;
  }
  .n-dash {
    margin-top: 20%;
  }
 .n-exist {
  flex-direction: row;
 }
 .n-da {
  margin-top: 20%;
}
}
.dropdown-menu {
  position: absolute;
  max-height: calc(100vh - 100px); 
  overflow-y: auto;
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
  .mbut{
    margin-bottom: 3%;
  }
  
}
@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
  .dropdown-menu {
      position: static; 
      width: 200%; 
      max-height: auto; 
      height: 550%;
    }
   
  /* .dropdown-item {
      display: block;
     
  } */
}
@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px){
  .ReactModal__Content.ReactModal__Content--after-open {
    max-width: 300px;
  
  }
}
@media (max-width: 1024px) {
  .ReactModal__Content.ReactModal__Content--after-open {
    max-width: auto;
  }
}







